.checkbox {
	display: flex;
	position: relative;
	font-size: 12px;
	color: #767676;
	cursor: pointer;

	&.checkbox-large {
		border-radius: 2px;
		border: 1px solid #858585;
		height: 14px;
		width: 14px;

		.checkbox-large-checkmark {
		}
	}
	
	.checkbox-input {
		height: 14px;
		margin: 0;
		opacity: 0;
		padding: 0;
		border: 0;
		top: 0;
		left: 0;
		position: absolute;
		visibility: collapse;
		width: 14px;

		&:focus {
			outline: none;
		}

		& + div {
			display: flex;
			align-items: center;

			& > *:not(:first-child) {
				margin-left: 10px;
			}

			svg {
				flex-shrink: 0;
				.checkbox-check {
					visibility: hidden;
				}
			}

			.checkbox-large-checkmark {
				visibility: hidden;
				opacity: 0;
			}
		}

		&:checked + div {
			svg {
				.checkbox-check {
					visibility: visible;
				}
			}

			.checkbox-large-checkmark {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.disabled {
		cursor: not-allowed;
	}
}
